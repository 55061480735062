import { graphql } from "gatsby"
import HomepageComponent from "@lekoarts/gatsby-theme-jodie-core/src/components/homepage"

export default HomepageComponent

export const query = graphql`
  query {
    allFile(filter: {relativeDirectory: {eq: "home"}}, sort: { fields: name, order: DESC }) {
      edges {
        node {
          name
          childrenImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
