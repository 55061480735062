/** @jsx jsx */
import { jsx } from "theme-ui"
import * as React from "react"
import { PageProps } from "gatsby"
import { IGatsbyImageData, GatsbyImage } from "gatsby-plugin-image"
import Layout from "@lekoarts/gatsby-theme-jodie/src/components/layout"
import GridGroup from "./grid-group"
import locales from "@lekoarts/gatsby-theme-jodie/src/locales"
import { visuallyHidden } from "@lekoarts/gatsby-theme-jodie/src/styles/utils"

type DataProps = {
  allFile: {
    edges: {
      node: {
        name: string
        childrenImageSharp: {
          gatsbyImageData: IGatsbyImageData
        }[]
      }
    }[]
  }
}

const Homepage: React.FC<PageProps<DataProps>> = ({ data: { allFile: {edges } } }) => {

  const items = edges
  .map( item => item.node)
  .sort((a, b) => {
    const indexA = parseInt(/\d+/.exec(a.name)[0]);
    const indexB = parseInt(/\d+/.exec(b.name)[0]);

    return indexA < indexB ? -1 : (indexA === indexB ? 0 : 1);
  })

  const squashedItems = [];
  let itemsCount = items.length

  for (let i=0; i < itemsCount; i++) {
    if (/\d+$/.exec(items[i].name)){
      squashedItems.push(items[i])
    } else {
      squashedItems.push([items[i], items[i + 1]])
      i++;
    }
  }

  return (
    <Layout>
      <h1 sx={visuallyHidden} data-testid="page-title">
        {locales.home}
      </h1>
      <div className={`item-list-wrapper`}>
        {
          squashedItems.map((item, index) => {
            if (item.length){
              return <GridGroup className="item" key={item[0].name} data-testid={item.name} sx={{marginBottom: "1rem"}}>
              <GatsbyImage
                loading={index === 0 ? `eager` : `lazy`}
                image={item[0].childrenImageSharp[0].gatsbyImageData}
                alt="Children's illustration"
              />
              <GatsbyImage
                loading={index === 0 ? `eager` : `lazy`}
                image={item[1].childrenImageSharp[0].gatsbyImageData}
                alt="Children's illustration"
              />
            </GridGroup>
            }

            return <div className="item" key={item.name} data-testid={item.name} sx={{marginBottom: index === squashedItems.length -1 ? 0 : "1rem"}}>
              <GatsbyImage
                loading={index === 0 ? `eager` : `lazy`}
                image={item.childrenImageSharp[0].gatsbyImageData}
                alt="Children's illustration"
              />
            </div>
          })
         }
      </div>
    </Layout>
  )
}

export default Homepage
