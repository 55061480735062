/** @jsx jsx */
import { jsx, get } from "theme-ui"
import * as React from "react"

const GridGroup: React.FC<React.PropsWithChildren<{ className?: string }>> = ({
  children,
  ...props
}) => (
  <div
    sx={{
      position: `relative`,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flex: 1,
      flexDirection: "row",
      // @ts-ignore
      "> img": {
        transition: `all 0.3s ease 0s !important`,
      },
      "> div + div": {
        marginLeft: "1rem",
      },
      "> a + a": {
        marginLeft: "1rem",
      },
      "@media screen and (max-width: 770px)": {
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
        "> div + div": {
          marginLeft: 0,
          marginTop: "1rem"
        },
        "> a + a": {
          marginLeft: 0,
          marginTop: "1rem"
        },
      },
    }}
    {...props}
  >
      {children}
  </div>
)

export default GridGroup
